<template>
  <div class="container">
    <NavTitle class="mb16" title="动态发布审核" :showBack="false">
      <template #right>
        <div class="input-row mr16 ml16">
          <el-input class="input" placeholder="请输入用户ID" clearable v-model="searchWord" @change="getDataList"></el-input>
          <el-button type="primary" icon="el-icon-search"  @click="getDataList"></el-button>
        </div>
      </template>
    </NavTitle>
    <div class="ml20 mr20">
      <el-menu  :default-active="menuType" mode="horizontal" active-text-color="#08C8BD" @select="menuSelect">
        <el-menu-item :index="item.type" v-for="(item, index) in menuList" :key="index" style="position:relative;">{{item.name}}</el-menu-item>
      </el-menu>
      <div class="menu-state">
        <el-menu  :default-active="stateType" mode="horizontal" active-text-color="#08C8BD" @select="stateSelect">
          <el-menu-item :index="item.type" v-for="(item, index) in stateList" :key="index" style="position:relative;">{{item.name}}</el-menu-item>
        </el-menu>
        <el-button type="primary" :disabled="!batchIds || batchIds.length == 0" @click="batchModify">批量修改</el-button>
      </div>
    </div>
    <div class="pl20 pr20 mt16">
      <el-table :data="dataList" style="width: 100%" v-loading="isLoading" @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <template v-if="menuType != 3">
          <el-table-column 
            prop="nick"
            label="用户名"
            width="120">
          </el-table-column>
          <el-table-column 
            prop="uid"
            label="用户ID"
            width="100">
          </el-table-column>
          <el-table-column 
            label="用户头像"
            width="100">
            <template slot-scope="scope">
              <LoadImage class="avatar" :src="scope.row.avatar" preview></LoadImage>
            </template>
          </el-table-column>
        </template>
        <template v-if="menuType == 3">
          <el-table-column 
            prop="fromNick"
            label="用户名"
            width="120">
          </el-table-column>
          <el-table-column 
            prop="toUid"
            label="用户ID"
            width="120">
          </el-table-column>
          <el-table-column 
            prop="toNick"
            label="被回复用户"
            width="120">
          </el-table-column>
          <el-table-column 
            prop="fromUid"
            label="被回复用户ID"
            width="120">
          </el-table-column>
          <el-table-column 
            prop="content"
            label="被回复内容">
          </el-table-column>
          <el-table-column 
            prop="replyContent"
            label="回复内容">
          </el-table-column>
        </template>
        
        <el-table-column 
          v-if="menuType != 3"
          label="图片"
          width="300">
          <template slot-scope="scope">
            <div class="img-list" v-if="menuType == 0 || menuType == 2">
              <div class="img" v-for="(pic, index) in scope.row.mediaUrls" :key="index">
                <LoadImage :src="pic" preview></LoadImage>
              </div>
            </div>
            <div class="img-list" v-if="menuType == 1">
              <div class="img" v-for="(pic, index) in scope.row.presents" :key="index">
                <LoadImage :src="pic" preview></LoadImage>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column 
          v-if="menuType != 3"
          prop="title"
          label="标题">
        </el-table-column>
        <el-table-column 
          v-if="menuType == 0"
          prop="content"
          label="内容">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              trigger="hover">
              <div slot="reference" v-if="scope.row.content">
                <span>{{scope.row.content.substring(0, 50)}}</span>
                <span v-if="scope.row.content.length > 50">...</span>
              </div>
              <div class="popover-content">{{scope.row.content}}</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column 
          v-if="menuType == 1"
          prop="desc"
          label="内容">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              trigger="hover">
              <div slot="reference" v-if="scope.row.desc">
                <span>{{scope.row.desc.substring(0, 50)}}</span>
                <span v-if="scope.row.desc.length > 50">...</span>
              </div>
              <div class="popover-content">{{scope.row.desc}}</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column 
          v-if="menuType == 2"
          prop="commentContent"
          label="内容">
        </el-table-column>
        <el-table-column 
          prop="gmtCreate"
          label="发布时间">
        </el-table-column>
        <el-table-column 
          label="操作">
          <template slot-scope="scope">
            <span class="theme-text pointer" v-if="menuType == 0 || menuType == 3" @click="openDetail(scope.row)">查看详情</span>
            <span class="theme-text ml16 pointer" @click="toModifyState(scope.row)">修改</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="flex justify-center mt24" v-if="dataList.length > 0">
        <Pagination :current-page="page.current + 1" @next-click="nextPage" @prev-click="prevPage"/>
      </div>
    </div>

    <!-- 动态详情 -->
    <MomentDetailPopup ref="momentDetailRef" @update="getDataList"></MomentDetailPopup>
    <!-- 修改状态 -->
    <ModifyStatusPopup ref="modifyStatusRef" @update="getDataList"></ModifyStatusPopup>
    <!-- 回复详情 -->
    <ReplyDetailPopup ref="replyDetailRef"></ReplyDetailPopup>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import LoadImage from '@/components/LoadImage'
import MomentDetailPopup from './components/MomentDetailPopup.vue'
import ModifyStatusPopup from './components/ModifyStatusPopup.vue'
import ReplyDetailPopup from './components/ReplyDetailPopup.vue'
import Pagination from '@/components/Pagination'
import { queryTopicApi, queryMomentApi, queryCommentApi, queryReplyApi } from '@/api/admin.js'
import { dateFormatUtil } from '@/utils/util.js'
export default {
  components: {
    NavTitle,
    LoadImage,
    Pagination,
    MomentDetailPopup,
    ModifyStatusPopup,
    ReplyDetailPopup
  },
  data() {
    return {
      searchWord: '',
      menuList: [
        {name:'动态审核', type:'0'},
        {name:'话题审核', type:'1'},
        {name:'评论审核', type:'2'},
        {name:'回复审核', type:'3'},
      ],
      stateList: [
        {name:'自己可见', type:'1'},
        {name:'朋友可见', type:'2'},
        {name:'公开', type:'3'},
        {name:'不可见', type:'0'},
      ],
      menuType: '0',
      stateType: '1',
      dataList: [],
      page: {
        current: 0,
        size: 10
      },
      isLoading: false,
      batchIds: []
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    async getDataList() {
      this.batchIds = []
      try {
        this.isLoading = true
        const formData = {
          state: Number(this.stateType),
          start: this.page.current,
          // pageSize: this.page.size,
          searchWord: this.searchWord.trim()
        }
        console.log('formData', formData)
        let list = []
        if(this.menuType == 0) {
          const res = await queryMomentApi(formData)
          list = res.data.moments || []
          console.log('动态列表', res)
        }
        if(this.menuType == 1) {
          const res = await queryTopicApi(formData)
          list = res.data.topics || []
          console.log('话题列表', res)
        }
        if(this.menuType == 2) {
          const res = await queryCommentApi(formData)
          list = res.data.comments || []
          console.log('评论列表', res)
        }
        if(this.menuType == 3) {
          const res = await queryReplyApi(formData)
          list = res.data.replies || []
          console.log('回复列表', res)
        }
        this.isLoading = false
        if(list.length != 0 || this.page.current == 0) {
          this.dataList = list.map(item => {
            if(item.gmtCreate) item.gmtCreate = dateFormatUtil(item.gmtCreate, '-', true)
            return item
          })
        } else {
          this.$tips({message: '没有更多数据了', type:'warning'})
          this.page.current--
        }
        
      } catch (err) {
        this.$tips({message: err, type: 'error'})
      }
    },
    menuSelect(e) {
      console.log(e)
      this.menuType = e
      this.page.current = 0
      this.getDataList()
    },
    stateSelect(e) {
      console.log(e)
      this.stateType = e
      this.page.current = 0
      this.getDataList()
    },
    openDetail(info) {
      if(this.menuType == 0) {
        this.$refs.momentDetailRef.open({detail: info})
      }
      if(this.menuType == 3) {
        this.$refs.replyDetailRef.open({detail: info})
      }
    },
    toModifyState(info) {
      let id = ''
      if(this.menuType == 0) id = info.mntId
      if(this.menuType == 1) id = info.tpcId
      if(this.menuType == 2) id = info.cmtId
      if(this.menuType == 3) id = info.replyId
      this.$refs.modifyStatusRef.open({
        type: this.menuType, 
        stateType: info.state,
        id
      })
    },
    nextPage() {
      this.page.current++
      this.getDataList()
    },
    prevPage() {
      this.page.current = this.page.current > 0 ? this.page.current - 1 : 0
      this.getDataList()
    },
    batchModify() {
      this.$refs.modifyStatusRef.open({
        isBatch: true, 
        batchIds: this.batchIds,
        type: this.menuType,
        stateType: Number(this.stateType),
      })
    },
    handleSelectionChange(e) {
      // console.log('多选', e)
      let id = ''
      if(this.menuType == 0) id = 'mntId'
      if(this.menuType == 1) id = 'tpcId'
      if(this.menuType == 2) id = 'cmtId'
      if(this.menuType == 3) id = 'replyId'
      this.batchIds = e.map(item => item[id])
    }
  }
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: #D9D9D9;
  border-radius: 32px;
}
::-webkit-scrollbar-track {
  background-color: #F6F6F6;
  border-radius: 32px;
}
.input-row {
  width: 240px;
  display: flex;
  align-items: center;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  overflow: hidden;
}
.img-list {
  display: flex;
  width: 300px;
  overflow-x: auto;
  
  .img {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    margin-right: 4px;
    border-radius: 4px;
    overflow: hidden;
  }
}
.popover-content {
  max-width: 30vw;
  max-height: 30vh;
  overflow-y: auto;
}
.menu-state {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}
</style>